$eerieBlack: #141414;
$coquelicot: #fc8970; // #e32c03;
$greenYrb: #548014; // #73ba26;
$davysGrey: #575757;
$platinum: #f6f3ee;

$primary: $eerieBlack;
$success: $greenYrb;
$danger: $coquelicot;
$backgroundLight: $platinum;
$backgroundDark: $eerieBlack;
$muted: $davysGrey;

@use '../../styles/colors';
@use '../../styles/fonts';

.fontFamilyLora {
	font-family: fonts.$fontFamily2;
}

.fontFamilyRoboto {
	font-family: fonts.$fontFamily;
}

// Font sizes
.fontSizeSmall {
	font-size: fonts.$t6FontSize;
}
.fontSizeNormal {
	font-size: fonts.$t5FontSize;
}
.fontSizeLarge {
	font-size: fonts.$t4FontSize;
}

// Line heights
.lineHeightSmall {
	line-height: fonts.$t6LineHeight;
}
.lineHeightNormal {
	line-height: fonts.$t5LineHeight;
}
.lineHeightLarge {
	line-height: fonts.$t4LineHeight;
}

// Font weights
.fontWeightNormal {
	font-weight: 200;
}
.fontWeightMedium {
	font-weight: 400;
}
.fontWeightBold {
	font-weight: 600;
}

// Font style
.fontStyleNormal {
	font-style: normal;
}
.fontStyleItalic {
	font-style: italic;
}

// Text decoraction
.textDecorationNone {
	text-decoration: none;
}
.textDecorationUnderline {
	text-decoration: underline;
}

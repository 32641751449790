// @import url('https://fonts.googleapis.com/css?family=Ubuntu');

$fontFamily: 'Roboto', sans-serif;
$fontFamily2: 'Lora', serif;

// PX calculated using https://grtcalculator.com/
// REM calculated using https://nekocalc.com/px-to-rem-converter
// Base font size is 16px
$t1FontSize: 2.625rem; // 42px
$t2FontSize: 2.063rem; // 33px
$t3FontSize: 1.625rem; // 26px
$t4FontSize: 1.25rem; // 20px
$t5FontSize: 1rem; // 16px
$t6FontSize: 0.813rem; // 13px

$t1LineHeight: 4.25rem; // 68px
$t2LineHeight: 3.375rem; // 54px
$t3LineHeight: 2.625rem; // 42px
$t4LineHeight: 2.063rem; // 33px
$t5LineHeight: 1.625rem; // 26px
$t6LineHeight: 1.25rem; // 20px

// Headers
$page: bold 2rem 'Arial', sans-serif;
$sectionBold: bold 1.5rem 'Arial', sans-serif;
$section: 1.5rem 'Arial', sans-serif;
$smallSectionBold: bold 0.75rem 'Arial', sans-serif;
$smallSection: 0.75rem 'Arial', sans-serif;

// Text
$largeBold: bold 1.25rem 'Arial', sans-serif;
$large: 1.25rem 'Arial', sans-serif;
$basicBold: bold 0.875rem 'Arial', sans-serif;
$basic: 0.875rem 'Arial', sans-serif;
$smallBold: bold 0.75rem 'Arial', sans-serif;
$small: 0.75rem 'Arial', sans-serif;

@use '../../ui/styles/colors';
@use '../../ui/styles/screen';

.page {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh;
	width: 100vw;
	background-color: colors.$platinum;
}

.innerPage {
	@include screen.forSize('phone-only') {
		margin: 24px;
	}
}

.contentContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	@include screen.forSize('tablet-portrait-up') {
		max-width: 450px;
	}
}

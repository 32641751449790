@use '../../styles/colors';
@use '../../styles/fonts';

.base {
	font-family: fonts.$fontFamily2;
	font-weight: 400;
}

// Font sizes
.h1 {
	font-size: fonts.$t1FontSize;
}
.h2 {
	font-size: fonts.$t2FontSize;
}
.h3 {
	font-size: fonts.$t3FontSize;
}
.h4 {
	font-size: fonts.$t4FontSize;
}
.h5 {
	font-size: fonts.$t5FontSize;
}

// Colors
.dark {
	color: white;
}
.light {
	color: colors.$primary;
}

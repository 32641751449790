@use '../../styles/colors';
@use '../../styles/fonts';

// Base
.button {
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border-style: solid;
	border-width: 1px;
	border-radius: 5px;
	padding: 10px 15px;
	font: fonts.$basic;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	border-radius: 999px;
	border-width: 1px;
}

.primaryFilled {
	border-color: colors.$primary;
	background-color: colors.$primary;
}
.primaryOutlined {
	border-color: colors.$primary;
	background-color: transparent;
}
.primaryTextOnly {
	border-color: transparent;
	background-color: transparent;
}

.successFilled {
	border-color: colors.$success;
	background-color: colors.$success;
}
.successOutlined {
	border-color: colors.$success;
	background-color: transparent;
}
.successTextOnly {
	border-color: transparent;
	background-color: transparent;
}

.dangerFilled {
	border-color: colors.$danger;
	background-color: colors.$danger;
}
.dangerOutlined {
	border-color: colors.$danger;
	background-color: transparent;
}
.dangerTextOnly {
	border-color: transparent;
	background-color: transparent;
}

.disabled {
	opacity: 0.5;
}

.paddingSmall {
	padding: 12px 12px;
}

.paddingNormal {
	padding: 14px 14px;
}

.paddingLarge {
	padding: 18px 18px;
}

.loadingContainer {
	display: flex;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	justify-content: center;
	align-items: center;
}

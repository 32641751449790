.loader {
	border: 2px solid transparent;
	border-top: 2px solid white;
	border-radius: 50%;
	animation: spin 0.75s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

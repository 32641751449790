@use './ui/styles/colors';

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Lora', serif;
	font-weight: 400;
	color: colors.$primary;
	line-height: 16px;
	margin: 0px;
}
